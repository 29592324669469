/* .blog-style--1 */
  
.content {
  margin-bottom: 30px;
}

/* Default styles for .blog-style--1 */
.blog-style--1 {
  margin-bottom: 20px; /* or any other default value */
}

/* Media query for screens 767px or less */
@media (max-width: 767px) {
  .blog-style--1 {
    margin-bottom: 0;
  }
}

.col-lg-4.col-md-6.col-12 {
  margin-top: 0; /* Default margin-top */
}

/* Media query for screens less than 768px wide (adjust the breakpoint as needed) */
@media (max-width: 767px) {
  .col-lg-4.col-md-6.col-12 {
    margin-top: 25px; /* Margin-top for mobile mode */
  }
}