.title {
    font-family: 'Brice-Black';
    text-align: center;
    color: #ef5474;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    font-size: 2rem;
    /* margin-top: 100px; */
  }

  @media (min-width: 567px) {
    .title {
      font-size: 3.0rem;
    }
  }
  
  .subtitle {
    color: black;
    font-family: 'Brice-Light';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 1.5rem;
    padding: 10px 0;
  }

  .rn-about-wrapper {
    margin-top: 50px;
  }
  
  .section-title {
    margin-top: 20px;
  }

  .w-100 {
    /* Default styles for .w-100 */
    width: 100%;
  }
  
  @media (max-width: 567px) and (min-width: 400px) {
    .w-100 {
      /* Styles for .w-100 between 567px and 400px */
      width: 200px;
    }
  }
  
/* Slider */

/* Add this CSS to your stylesheet */
.slider-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
  }
  
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
  }
  
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  
  .slick-slide img {
    width: 100%;
    height: auto;
  }
  .slick-prev,
  .slick-next {
    font-size: 24px;
    line-height: 1;
    color: #000; /* Set your arrow color */
    cursor: pointer;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #333; /* Set your arrow color on hover */
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .slick-dots li {
    margin: 0 4px;
  }
  
  .slick-dots button {
    font-size: 10px;
    line-height: 1;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    color: #000; /* Set your dot color */
    cursor: pointer;
    border: 1px solid #000; /* Set your dot border color */
    border-radius: 50%;
    background: transparent;
  }
  
  .slick-dots button:hover {
    background: #b96363; /* Set your dot background color on hover */
    color: #fff; /* Set your dot color on hover */
  }
      
  