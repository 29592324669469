/* Default styles for the element */
.col-lg-4.col-md-6.col-12 {
    margin-top: 10px; 
    height: 450px; /* Default height for larger screens */
    
    width: 100%;
  }
.container {
    width: 100%;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* background-color: #ded6aa; */
  }
  
  
  /* Media query for screens less than 768px wide */
  @media (max-width: 767px) {
    .col-lg-4.col-md-6.col-12 {
      height: 360px;
      /* padding-bottom: 10px; */
      /* margin-top: 0px;  */
    }
    .container {
        width: 100%;
    }
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    .col-lg-4.col-md-6.col-12 {
      /* margin-top: 0px;  */
      height: 350px;
      /* padding-bottom: 10px; */
      object-fit: cover;
    }
  }
  @media (max-width: 991px) and (min-width: 767px) {
    .col-lg-4.col-md-6.col-12 {
      /* margin-top: 0px;  */
      /* padding-bottom: 10px; */
      height: 300px;
      object-fit: cover;
    }
  }
  @media (max-width: 576px){
    .col-lg-4.col-md-6.col-12 {
       /* Adjust the margin for screens between 1199px and 992px */
      /* padding-bottom: 10px; */
      /* margin: 10px;
      padding: 10px;
      height: 300px;
      object-fit: cover; */
    }
  }
  @media (max-width: 767px) and (min-width: 400px) {
    .col-lg-4.col-md-6.col-12 {
      /* margin-top: 0px;  */
      height: 323px;
      /* padding-bottom: 10px; */
      object-fit: cover;
    }
  }

  @media (max-width: 399px) and (min-width: 320px) {
    .col-lg-4.col-md-6.col-12 {
      /* margin-top: 0px;  */
      height: 200px;
      object-fit: cover;
    }
  }

  @media (max-width: 319px) {
    .col-lg-4.col-md-6.col-12 {
      /* margin-top: 0px;  */
      height: 100px;
      object-fit: cover;
    }
  }


  /* Add a negative margin to reduce spacing between elements */

  

  