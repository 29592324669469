/* Add this CSS to your stylesheets */

/* Image container styles */
.image-container {
    height: 400px; /* Set your desired fixed height */
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  /* Image styles */
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Adjust this based on your preference */
  }
  
  /* Zoom effect on hover */
  .image-container img:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Slick Carousel styles */
  .portfolio-slick-activation {
    position: relative;
  }
  
  .portfolio-slick-activation button.slick-arrow {
    position: absolute;
    z-index: 3;
    background: transparent;
    font-size: 20px;
    height: 101%;
  }
  
  .portfolio-slick-activation button.slick-arrow::before {
    line-height: 1;
    opacity: 1;
    font-size: 30px;
    position: relative;
    top: -14px;
    transition: 0.5s;
  }
  
  .portfolio-slick-activation button.slick-prev,
  .portfolio-slick-activation button.slick-next {
    
    bottom: 0; /* Adjust the positioning to be fixed at the bottom */
    top: auto; /* Override the top property */
    margin-top: 0; /* Remove any top margin */
  }
  
  .portfolio-slick-activation button.slick-prev {
    left: 0;
    /* ... (Rest of the styles for .slick-prev) */
  }
  

  .portfolio-slick-activation button.slick-next {
    right: 50px; /* Adjust the distance from the right edge */
    bottom: 0;
    transform: translateY(50%);
    /* ... (Rest of the styles for .slick-next) */
  }
  
  .portfolio-slick-activation button:hover {
    /* ... Hover styles */
  }
  
  /* Slick Gutter Space */
  .slick-space-gutter--15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  .slick-space-gutter--15 .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .portfolio {
    margin: 40px 0;
  }
  
  .slickdot--20 .slick-dots {
    bottom: -20px;
  }
  