/* Add this CSS to style the dropdown menu */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff; /* Background color of the dropdown menu */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add a shadow to the dropdown menu */
    z-index: 1;
}

.dropdown:hover .dropdown-menu {
    display: block; /* Show the dropdown menu when hovering over the 'Catalog' link */
}

.dropdown-menu li {
    padding: 10px; /* Adjust padding as needed */
}

.dropdown-menu a {
    color: #333; /* Text color for dropdown options */
    text-decoration: none;
    display: block;
}

.dropdown-menu a:hover {
    background-color: #f2f2f2; /* Background color on hover */
}

@media (max-width: 767px) {
    .customParkTubes {
      margin: 10px;
    }
  }


/* interiorStyles.css */


  
  .col-lg-6.customParkTubes .section-title {
    border-radius: 25px;
    margin-bottom: 0px;
  }
  
  .col-lg-6.customParkTubes .animated-text {
    animation: fontSizeAnimation 2s infinite alternate;
  }
  
  .col-lg-6.customParkTubes .title {
    font-size: 3rem;
    font-family: 'Brice-Black';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    padding-bottom: 0px;
  }
  
  /* You can add more specific selectors and styles as needed */
  
  /* the big image  */


.backgroundInterior {
  /* Your existing styles for the .backgroundInterior image */
  width: 80%; /* Set the image width to 80% */
}

@media screen and (min-width: 1600px) {
  .backgroundInterior {
      min-width: 1700px;
  }
}

@media screen and (max-width: 1999px) and (min-width: 1600px) {
  .backgroundInterior {
      min-width: 2000px;
  }
}
@media screen and (max-width: 1599px) and (min-width: 1300px) {
  .backgroundInterior {
      min-width: 1600px;
  }
}
@media screen and (max-width: 1299px) and (min-width: 1000px) {
  .backgroundInterior {
      min-width: 1300px;
  }
}

@media screen and (max-width: 999px) and (min-width: 767px) {
  .backgroundInterior {
      min-width: 1000px;
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  .backgroundInterior {
      min-width: 770px;
      /* max-height: 300px; */
  }
  .merchandise {
    font-size: 30px;
  }
}
@media screen and (max-width: 499px) and (min-width: 200px) {
  .backgroundInterior {
      min-width: 500px;
      /* max-height: 300px; */
  }
  .merchandise {
    font-size: 30px;
  }
}

/*  Menu */

@media screen and (max-width: 993px) {
  .disappearCustom {
      display: none;
  }
}


