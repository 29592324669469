/*--------------------------
Call To Action Wrapper 
-----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@font-face {
    font-family: 'Brice-Black';
    src: url('/assets/fonts/Brice Font Family/Brice-Black.otf') format('opentype');
    /* You can specify other font formats if needed (e.g., woff, woff2, ttf). */
  }
  
@font-face {
font-family: 'Brice-Light';
src: url('/assets/fonts/Brice Font Family/Brice-Light.otf') format('opentype');
/* You can specify other font formats if needed (e.g., woff, woff2, ttf). */
}

.call-to-action {
    background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(/assets/images/pattern-1.png);
        content: "";
        z-index: -1;
        opacity: 0.5;
    }
    .inner {
        > span {
            color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: block;
            margin-bottom: 9px;
            @media #{$sm-layout} {
                display: none;
                font-size: 47px;
                line-height: 70px;
            }
        }
        h2 {
            color: #ffffff;
            font-size: 75px;
            font-weight: 900;
            // font-family: 'Montserrat', sans-serif;
            // font-family: 'Dancing Script', cursive;
            font-family: 'Brice-Black', sans-serif;
            line-height: 90px;
            @media #{$sm-layout} {
                display: none;
                font-size: 47px;
                line-height: 70px;
            }
        }
        a {
            &.rn-button-style--2 {
                margin-top: 30px;
                &:hover {
                    background: #ffffff;
                    border-color: #ffffff;
                    color: $theme-color;
                }
            }
        }
    }
    &.bg_image {
        &::before {
            display: none;
        }
    }
}